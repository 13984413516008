// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

.error-snackbar {
    background: #F44336 !important;
    color: white !important;

    .mat-simple-snackbar-action {
      color: white !important;
    }
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.info-input {
  mat-label {
    color: black;
  }

  .mat-input-element:disabled {
    color: black !important;
  }
}

.warn-info-input {
  mat-label {
    color: black;
  }

  .mat-input-element:disabled {
    color: red !important;
  }
}

.mat-form-field.no-padding > .mat-form-field-wrapper {
  margin-bottom: -1.05em;
}

.attachment-drop-zone {
  border: 2px dashed silver;
  border-radius: 8px;
  min-height: 120px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.attachment-content {
  min-height: 120px;
  height: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.mat-focused .mat-form-field-label {
  color: $default-accent-color  !important;
}

.mat-form-field-underline {
  background-color: $default-accent-color  !important;
}

.mat-form-field-ripple {
  background-color: $default-accent-color  !important;
}

input {
  caret-color: $default-accent-color  !important;
}